import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import Vue from 'vue';
import 'normalize.css/normalize.css';
import '@/styles/index.scss';
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/icons';
import App from '@/App.vue';
import '@/permission';
import '@/pwa/registerServiceWorker';
import router from '@/router';
import store from '@/store';
import Report from '@/utils/report';
import { initPlatform } from '@/utils';
import bus from '@/utils/bus';
import filters from '@/filters';
import i18n, { VueVantLocales } from '@/lang';
import VueQriously from 'vue-qriously'; // 引入样式

import 'vue-easytable/libs/themes-base/index.css';
import { VTable } from 'vue-easytable'; // 注册到全局

Vue.component(VTable.name, VTable);
VueVantLocales();
initPlatform();
Vue.config.productionTip = false;
var _process$env = process.env,
    NODE_ENV = _process$env.NODE_ENV,
    VUE_APP_SENTRY_ENABLED = _process$env.VUE_APP_SENTRY_ENABLED;
var PROD = NODE_ENV === 'production'; // if (PROD && VUE_APP_SENTRY_ENABLED === 'yes') {
//   const { VUE_APP_SENTRY_DSN } = process.env
//   const sentry = Report.getInstance(Vue, {
//     dsn: VUE_APP_SENTRY_DSN,
//     release: __VERSION__,
//     environment: 'Prod'
//   })
//   window.$sentry = sentry
//   Vue.config.errorHandler = (error, vm, info) => {
//     window.$sentry.log({
//       error,
//       type: 'vue errorHandler',
//       vm,
//       info
//     })
//   }
// }

Object.keys(filters).forEach(function (filterName) {
  Vue.filter(filterName, filters[filterName]);
});
Vue.use(Vant).use(bus);
Vue.use(VueQriously);
export default new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');