"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lang = require("@/lang");

var _cache = require("@/core/services/cache");

var state = {
  language: (0, _lang.getLocale)()
};
var mutations = {
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;
    (0, _cache.setLanguage)(language);
  },
  SET_NEW_SYSMESSAGE_COUNT: function SET_NEW_SYSMESSAGE_COUNT(state, count) {
    state.new_sysmessage_count = count;
  }
};
var actions = {
  SetLanguage: function SetLanguage(_ref, language) {
    var commit = _ref.commit;
    commit('SET_LANGUAGE', language);
  },
  SetSysmessageCount: function SetSysmessageCount(_ref2, count) {
    var commit = _ref2.commit;
    commit('SET_NEW_SYSMESSAGE_COUNT', count);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;