"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _Flyin = _interopRequireDefault(require("@/components/Flyin"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'FlyinDemo',
  components: {
    Flyin: _Flyin.default
  },
  data: function data() {
    return {
      balls: [],
      elLeft: 0,
      elTop: 0,
      productNum: 5
    };
  },
  methods: {
    onClickIcon: function onClickIcon() {
      this.$toast('请点击列表加入购物车测试！');
    },
    onClickButton: function onClickButton() {
      this.$toast('请点击列表加入购物车测试！');
    },
    addToCart: function addToCart() {
      this.productNum = this.productNum + 1;
      this.elLeft = event.target.getBoundingClientRect().left;
      this.elTop = event.target.getBoundingClientRect().top;
      this.balls = [].concat((0, _toConsumableArray2.default)(this.balls), [true]);
    }
  }
};
exports.default = _default;