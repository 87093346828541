"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DoctorCareList = exports.DoctorCareNotice = exports.GetAreas = exports.TargetList = exports.IndexCRM = exports.SystemMessageRead = exports.UpdateAnnouncement = exports.PromoterDoctorRank = exports.PromoterDoctorActivities = exports.SystemMessageConfigList = exports.SystemMessageConfigSave = exports.SystemMessageList = exports.VersionList = exports.AgentIndex = exports.PartnerPeport = exports.PromoterInfo = exports.Index = void 0;
var Index = '/api/index';
exports.Index = Index;
var PromoterInfo = '/api/promoter_info';
exports.PromoterInfo = PromoterInfo;
var PartnerPeport = '/api/partner_peport';
exports.PartnerPeport = PartnerPeport;
var AgentIndex = '/api/agent_index';
exports.AgentIndex = AgentIndex;
var VersionList = '/api/version_list';
exports.VersionList = VersionList;
var SystemMessageList = '/api/system_message_list';
exports.SystemMessageList = SystemMessageList;
var SystemMessageConfigSave = '/api/config_save';
exports.SystemMessageConfigSave = SystemMessageConfigSave;
var SystemMessageConfigList = '/api/config_list';
exports.SystemMessageConfigList = SystemMessageConfigList;
var PromoterDoctorActivities = '/api/promoter_doctor_activities';
exports.PromoterDoctorActivities = PromoterDoctorActivities;
var PromoterDoctorRank = '/api/promoter_doctor_rank';
exports.PromoterDoctorRank = PromoterDoctorRank;
var UpdateAnnouncement = '/api/update_announcement';
exports.UpdateAnnouncement = UpdateAnnouncement;
var SystemMessageRead = '/api/system_message_read';
exports.SystemMessageRead = SystemMessageRead;
var IndexCRM = '/api/get_target_index';
exports.IndexCRM = IndexCRM;
var TargetList = '/api/get_target_list';
exports.TargetList = TargetList;
var GetAreas = '/common/get_areas';
exports.GetAreas = GetAreas;
var DoctorCareNotice = '/api/doctor_care_notice';
exports.DoctorCareNotice = DoctorCareNotice;
var DoctorCareList = '/api/doctor_care_list';
exports.DoctorCareList = DoctorCareList;