"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PointLogs = exports.PointIndex = exports.ActivityLKMultipleJan = exports.ActivityLKReceiveJan = exports.ActivityLKDetailJan = exports.ActivityDoctorReportURL = exports.ActivityDoctorReport2022Share = exports.ActivityDoctorReport2022 = exports.ActivityConfigReceive = exports.ActivityConfigDetail = exports.ActivitySubsidiesDetail = exports.ActivityNationalDetail = exports.ActivitySettledReceive = exports.ActivitySettledDetail = exports.ActivityTargetDetail = exports.ActivityInviteDetailCash = exports.ActivityInviteDetail = exports.ActivityInviteIndex = exports.ActivityLKReceive = exports.ActivityLKDetail = exports.ActivityXTReceive = exports.ActivityXTDetail = exports.GetImgConfig = exports.MallCheckCoupons = exports.ActivityGBDetail = exports.ActivityReceive = exports.ActivityReturn = exports.MallStoreCategories = exports.MallStoreActivityDetail = exports.MallStoreActivities = exports.MallDoctorCardDetail = exports.MallDoctorCards = exports.MallGetLogisticsTracking = exports.MallGetIntegralOrderList = exports.MallGetAllAreas = exports.MallCreateAddress = exports.MallCreateOrder = exports.MallAddressList = exports.MallGoodsInfo = exports.MallBuyGoods = exports.MallStoreIndex = exports.MallStoreList = exports.MallStoreSlides = exports.MallIntegralInfo = void 0;
var MallIntegralInfo = '/api/integral_info';
exports.MallIntegralInfo = MallIntegralInfo;
var MallStoreSlides = '/api/store_slides';
exports.MallStoreSlides = MallStoreSlides;
var MallStoreList = '/api/store_index';
exports.MallStoreList = MallStoreList;
var MallStoreIndex = '/api/v2/store_index';
exports.MallStoreIndex = MallStoreIndex;
var MallBuyGoods = '/api/buy_goods';
exports.MallBuyGoods = MallBuyGoods;
var MallGoodsInfo = '/api/goods_info';
exports.MallGoodsInfo = MallGoodsInfo;
var MallAddressList = '/api/address_list';
exports.MallAddressList = MallAddressList;
var MallCreateOrder = '/api/create_integral_order';
exports.MallCreateOrder = MallCreateOrder;
var MallCreateAddress = '/api/create_address';
exports.MallCreateAddress = MallCreateAddress;
var MallGetAllAreas = '/common/get_areas';
exports.MallGetAllAreas = MallGetAllAreas;
var MallGetIntegralOrderList = '/api/integral_order_list';
exports.MallGetIntegralOrderList = MallGetIntegralOrderList;
var MallGetLogisticsTracking = '/api/logistics_tracking';
exports.MallGetLogisticsTracking = MallGetLogisticsTracking;
var MallDoctorCards = '/api/doctor_cards';
exports.MallDoctorCards = MallDoctorCards;
var MallDoctorCardDetail = '/api/doctor_card_detail';
exports.MallDoctorCardDetail = MallDoctorCardDetail;
var MallStoreActivities = '/api/store_activities';
exports.MallStoreActivities = MallStoreActivities;
var MallStoreActivityDetail = '/api/store_activity_detail';
exports.MallStoreActivityDetail = MallStoreActivityDetail;
var MallStoreCategories = '/api/store_categories';
exports.MallStoreCategories = MallStoreCategories;
var ActivityReturn = '/api/activity_hg_detail';
exports.ActivityReturn = ActivityReturn;
var ActivityReceive = '/api/activity_hg_receive';
exports.ActivityReceive = ActivityReceive;
var ActivityGBDetail = '/api/activity_gb_detail';
exports.ActivityGBDetail = ActivityGBDetail;
var MallCheckCoupons = '/api/mall_check_coupons';
exports.MallCheckCoupons = MallCheckCoupons;
var GetImgConfig = '/common/get_img_config';
exports.GetImgConfig = GetImgConfig;
var ActivityXTDetail = '/api/activity_xt_detail';
exports.ActivityXTDetail = ActivityXTDetail;
var ActivityXTReceive = '/api/activity_xt_receive';
exports.ActivityXTReceive = ActivityXTReceive;
var ActivityLKDetail = '/api/activity_lk_detail';
exports.ActivityLKDetail = ActivityLKDetail;
var ActivityLKReceive = '/api/activity_lk_receive';
exports.ActivityLKReceive = ActivityLKReceive;
var ActivityInviteIndex = '/api/activity_invite_index';
exports.ActivityInviteIndex = ActivityInviteIndex;
var ActivityInviteDetail = '/api/activity_invite_detail';
exports.ActivityInviteDetail = ActivityInviteDetail;
var ActivityInviteDetailCash = '/api/activity_invite_cash';
exports.ActivityInviteDetailCash = ActivityInviteDetailCash;
var ActivityTargetDetail = '/api/activity_target_detail';
exports.ActivityTargetDetail = ActivityTargetDetail;
var ActivitySettledDetail = '/api/activity_settled_detail';
exports.ActivitySettledDetail = ActivitySettledDetail;
var ActivitySettledReceive = '/api/activity_settled_receive';
exports.ActivitySettledReceive = ActivitySettledReceive;
var ActivityNationalDetail = '/api/activity_national_detail';
exports.ActivityNationalDetail = ActivityNationalDetail;
var ActivitySubsidiesDetail = '/api/activity_subsidies_detail';
exports.ActivitySubsidiesDetail = ActivitySubsidiesDetail;
var ActivityConfigDetail = '/api/activity_config_detail';
exports.ActivityConfigDetail = ActivityConfigDetail;
var ActivityConfigReceive = '/api/activity_config_receive';
exports.ActivityConfigReceive = ActivityConfigReceive;
var ActivityDoctorReport2022 = '/api/doctor_report_2022';
exports.ActivityDoctorReport2022 = ActivityDoctorReport2022;
var ActivityDoctorReport2022Share = '/api/doctor_share_report_2022';
exports.ActivityDoctorReport2022Share = ActivityDoctorReport2022Share;
var ActivityDoctorReportURL = '/api/doctor_report_url';
exports.ActivityDoctorReportURL = ActivityDoctorReportURL;
var ActivityLKDetailJan = '/api/activity_lk_detail_jan';
exports.ActivityLKDetailJan = ActivityLKDetailJan;
var ActivityLKReceiveJan = '/api/activity_lk_receive_jan';
exports.ActivityLKReceiveJan = ActivityLKReceiveJan;
var ActivityLKMultipleJan = '/api/activity_lk_multiple_jan';
exports.ActivityLKMultipleJan = ActivityLKMultipleJan;
var PointIndex = '/api/point_index';
exports.PointIndex = PointIndex;
var PointLogs = '/api/point_logs';
exports.PointLogs = PointLogs;