import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import _defineProperty from "/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _ref;

import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout';
import componentsRouter from './modules/components';
Vue.use(VueRouter);
export var routes = [{
  path: '/login',
  component: function component() {
    return import('@/pages/login/index');
  },
  name: 'Login',
  meta: {
    title: '登录'
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return import('@/pages/redirect/index');
    }
  }]
}, {
  path: '',
  component: Layout,
  redirect: '/home',
  children: [{
    path: 'home',
    component: function component() {
      return import('@/pages/home/index');
    },
    name: 'Home',
    meta: {
      title: '首页',
      right_title: '消息',
      right_push: 'Messages'
    }
  }, {
    path: 'qrcode',
    component: function component() {
      return import('@/pages/qrcode/index');
    },
    name: 'Qrcode',
    meta: {
      title: '邀请医生'
    }
  }, {
    path: 'inviteDoctor',
    component: function component() {
      return import('@/pages/inviteDoctor/index');
    },
    name: 'InviteDoctor',
    meta: {
      title: '诚邀您加入喜郎中',
      hideNav: true
    }
  }, {
    path: 'partnerDetail',
    component: function component() {
      return import('@/pages/partnerDetail/index');
    },
    name: 'partnerDetail'
  }, {
    path: 'orderList',
    component: function component() {
      return import('@/pages/orderList/index');
    },
    name: 'OrderList',
    meta: {
      title: '订单统计'
    }
  }, {
    path: 'statisticsDoctorList',
    component: function component() {
      return import('@/pages/statisticsDoctorList/index');
    },
    name: 'StatisticsDoctorList',
    meta: {
      title: '医生统计'
    }
  }, {
    path: 'doctorActivities',
    component: function component() {
      return import('@/pages/doctorActivities/index');
    },
    name: 'DoctorActivities',
    meta: {
      title: '医生活动列表'
    }
  }]
}, {
  path: '',
  component: Layout,
  redirect: '/messages',
  children: [{
    path: 'messages',
    component: function component() {
      return import('@/pages/messages/index');
    },
    name: 'Messages',
    meta: {
      title: '消息',
      right_title: '设置',
      right_push: 'MessageSettings'
    }
  }, {
    path: 'messageSettings',
    component: function component() {
      return import('@/pages/messages/messageSettings');
    },
    name: 'MessageSettings',
    meta: {
      title: '消息设置'
    }
  }]
}, {
  path: '',
  component: Layout,
  redirect: '/forgot',
  children: [{
    path: 'forgot',
    component: function component() {
      return import('@/pages/forgot/index');
    },
    name: 'Forgot',
    meta: {
      title: '忘记密码'
    }
  }]
}, {
  path: '',
  component: Layout,
  redirect: '/team',
  children: [{
    path: 'team',
    component: function component() {
      return import('@/pages/team/index');
    },
    name: 'Team',
    meta: {
      title: '我的团队',
      keepAlive: true
    }
  }, {
    path: 'teamMember',
    component: function component() {
      return import('@/pages/teamMember/index');
    },
    name: 'TeamMember',
    meta: {
      title: ''
    }
  }]
}, {
  path: '',
  component: Layout,
  redirect: '/pharmacyList',
  children: [{
    path: 'pharmacyList',
    component: function component() {
      return import('@/pages/pharmacyList/index');
    },
    name: 'PharmacyList',
    meta: {
      title: '我的代理商',
      keepAlive: false
    }
  }, {
    path: 'pharmacyDetail',
    component: function component() {
      return import('@/pages/pharmacyDetail/index');
    },
    name: 'pharmacyDetail'
  }]
}, {
  path: '',
  component: Layout,
  redirect: '/doctors',
  children: [{
    path: 'doctors',
    component: function component() {
      return import('@/pages/doctors/index');
    },
    name: 'Doctors',
    meta: {
      title: '医生',
      keepAlive: true,
      right_title: '医生排名',
      right_push: 'DoctorRank'
    }
  }, {
    path: 'doctorRank',
    component: function component() {
      return import('@/pages/doctors/doctorRank');
    },
    name: 'DoctorRank',
    meta: {
      title: '排行榜',
      keepAlive: true,
      right_title: '提示',
      is_alert: true
    }
  }, {
    path: 'follow',
    component: function component() {
      return import('@/pages/doctors/follow');
    },
    name: 'Follow',
    meta: {
      title: '新增跟进'
    }
  }, {
    path: 'visit',
    component: function component() {
      return import('@/pages/doctors/visit');
    },
    name: 'Visit',
    meta: {
      title: '新增拜访',
      keepAlive: true
    }
  }, {
    path: 'visitDoctorList',
    component: function component() {
      return import('@/pages/doctors/visitDoctorList');
    },
    name: 'VisitDoctorList',
    meta: {
      title: '选择医生'
    }
  }, {
    path: 'visitDoctorEdit',
    component: function component() {
      return import('@/pages/doctors/visitDoctorEdit');
    },
    name: 'visitDoctorEdit',
    meta: {
      title: '添加医生'
    }
  }]
}, {
  path: '',
  component: Layout,
  redirect: '/doctorDetail/:id',
  children: [{
    path: 'doctorDetail',
    component: function component() {
      return import('@/pages/doctorDetail/index');
    },
    name: 'DoctorDetail',
    meta: {
      title: '医生详情'
    }
  }, {
    path: 'DoctorEditInfo',
    component: function component() {
      return import('@/pages/doctorDetail/doctorEditInfo');
    },
    name: 'DoctorEditInfo',
    meta: {
      title: '医生详情'
    }
  }, {
    path: 'doctorTags',
    component: function component() {
      return import('@/pages/doctorDetail/doctorTags');
    },
    name: 'DoctorTags',
    meta: {
      title: '医生标签'
    }
  }]
}, {
  path: '',
  component: Layout,
  redirect: '/targetDetail',
  children: [{
    path: 'targetDetail',
    component: function component() {
      return import('@/pages/target/targetDetail');
    },
    name: 'TargetDetail',
    meta: {
      title: '业绩目标完成度'
    }
  }]
}, {
  path: '',
  component: Layout,
  redirect: '/mine',
  children: [{
    path: 'mine',
    component: function component() {
      return import('@/pages/mine/index');
    },
    name: 'Mine',
    meta: {
      title: '我的',
      keepAlive: true
    }
  }, {
    path: 'doctorCareList',
    component: function component() {
      return import('@/pages/doctorCareList/index');
    },
    name: 'DoctorCareList',
    meta: {
      title: '流失预警',
      is_alert: true,
      right_title: '提示'
    }
  }, {
    path: 'versionList',
    component: function component() {
      return import('@/pages/versionList/index');
    },
    name: 'VersionList',
    meta: {
      title: '喜郎中APP版本更新记录',
      keepAlive: true
    }
  }, {
    path: 'mallList',
    component: function component() {
      return import('@/pages/mall/mallList');
    },
    name: 'MallList',
    meta: {
      title: '积分商城',
      keepAlive: true
    }
  }, (_ref = {
    path: 'mallItemDetail/:id',
    name: 'MallItemDetail',
    component: function component() {
      return import('@/pages/mall/mallItemDetail');
    }
  }, _defineProperty(_ref, "name", 'mallList'), _defineProperty(_ref, "meta", {
    title: '商品详情',
    keepAlive: false
  }), _ref), {
    path: '/mallItemEdit',
    name: 'MallItemEdit',
    component: function component() {
      return import('@/pages/mall/mallItemEdit');
    },
    meta: {
      title: '支付订单',
      keepAlive: false
    }
  }, {
    path: '/mallItemBuySuccess',
    name: 'MallItemBuySuccess',
    component: function component() {
      return import('@/pages/mall/mallItemBuySuccess');
    },
    meta: {
      title: '兑换成功'
    }
  }, {
    path: '/mallExpress',
    name: 'MallExpress',
    component: function component() {
      return import('@/pages/mall/mallExpress');
    },
    meta: {
      title: '物流详情'
    }
  }, {
    path: '/mallAddressEdit',
    name: 'MallAddressEdit',
    component: function component() {
      return import('@/pages/mall/mallAddressEdit');
    },
    meta: {
      title: '编辑地址'
    }
  }, {
    path: '/points',
    name: 'Points',
    component: function component() {
      return import('@/pages/points/index');
    },
    meta: {
      title: '个人积分',
      right_title: '规则',
      right_push: 'points_web'
    }
  }, {
    path: '/pointsDetailList',
    name: 'PointsDetailList',
    component: function component() {
      return import('@/pages/points/pointsDetailList');
    },
    meta: {
      title: '积分明细'
    }
  }]
}, componentsRouter];

var createRouter = function createRouter() {
  return new VueRouter({
    mode: 'history',
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: routes
  });
};

var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
export default router;