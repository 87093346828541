import "core-js/modules/es.function.name";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { VueVantLocales } from '@/lang';
import { Toast, Dialog } from 'vant';
export default {
  name: 'Navbar',
  data: function data() {
    return {
      langOption: [{
        text: '中文',
        value: 'zh'
      }, {
        text: 'English',
        value: 'en'
      }],
      messagesCount: 0
    };
  },
  watch: {
    $route: function $route(to, from) {
      //这个地方写逻辑代码
      var toPath = to.path.split('/')[1];
      var query = to.query;
      console.log(to);
      console.log(query);

      if (toPath == 'doctorCareList') {
        switch (query.type) {
          case 1:
            to.meta.title = '流失预警';
            break;

          case 2:
            to.meta.title = '维护提醒';
            break;

          case 3:
            to.meta.title = '生日提醒';
            break;

          default:
            break;
        }
      }

      if (toPath == 'pharmacyDetail') {
        to.meta.title = query.name;
      }

      if (toPath == 'partnerDetail') {
        to.meta.title = query.name;
      }
    }
  },
  computed: {
    language: {
      get: function get() {
        return this.$store.state.app.language;
      },
      set: function set(val) {
        VueVantLocales(val);
        this.$i18n.locale = val;
        this.$store.dispatch('app/SetLanguage', val);
      }
    },
    pageTitle: function pageTitle() {
      return this.$route.meta.title;
    },
    rightTitle: function rightTitle() {
      return this.$route.meta.right_title;
    }
  },
  created: function created() {
    var _this = this;

    this.$bus.$on("new_sysmessage_count", function (count) {
      console.log('new_sysmessage_count');
      console.log(count);
      _this.messagesCount = count;
    });
  },
  methods: {
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight: function onClickRight() {
      console.log(this.$route);

      if (this.$route.name == 'Points') {
        window.location.href = 'https://admin.5ixlz.com/common/article_detail?id=130';
      }

      if (this.$route.name == 'DoctorCareList') {
        if (this.$route.query.type == 1 || this.$route.query.type == 2) {
          Dialog.alert({
            title: '温馨提示',
            messageAlign: 'left',
            confirmButtonColor: '#ff9000',
            message: this.$route.query.type == 1 ? '对超过30日未处方的医生进行流失预警' : '对超过15日未登录喜郎中APP的医生进行维护提醒'
          }).then(function () {// on close
          });
        } else {
          Dialog.alert({
            title: '温馨提示',
            messageAlign: 'left',
            confirmButtonColor: '#ff9000',
            message: '医生生日倒计时提醒'
          }).then(function () {// on close
          });
        }

        return;
      }

      if (this.$route.meta.is_alert) {
        Dialog.alert({
          title: '温馨提示',
          messageAlign: 'left',
          confirmButtonColor: '#ff9000',
          message: '• 排行榜按照医生开方总药费从高至低排序\n• 排行榜数据每日凌晨更新'
        }).then(function () {// on close
        });
      } else {
        this.$router.push({
          name: this.$route.meta.right_push
        });
      }
    }
  }
};