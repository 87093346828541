"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SendCode = exports.ForgotPassword = exports.ULogin = exports.Login = void 0;
var Login = '/api/login';
exports.Login = Login;
var ULogin = '/api/user_login';
exports.ULogin = ULogin;
var ForgotPassword = '/api/forgot_password';
exports.ForgotPassword = ForgotPassword;
var SendCode = '/common/newsend_sms_code';
exports.SendCode = SendCode;