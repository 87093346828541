"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cleanLocalStorage = exports.readFromLocalStorage = exports.saveToLocalStorage = exports.setLanguage = exports.getLanguage = exports.removeToken = exports.setToken = exports.getToken = void 0;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _settings = require("@/constants/settings");

var getToken = function getToken() {
  return _jsCookie.default.get(_settings.TOKEN_KEY);
};

exports.getToken = getToken;

var setToken = function setToken(token) {
  return _jsCookie.default.set(_settings.TOKEN_KEY, token);
};

exports.setToken = setToken;

var removeToken = function removeToken() {
  return _jsCookie.default.remove(_settings.TOKEN_KEY);
};

exports.removeToken = removeToken;

var getLanguage = function getLanguage() {
  return _jsCookie.default.get(_settings.LANGUAGE_KEY);
};

exports.getLanguage = getLanguage;

var setLanguage = function setLanguage(language) {
  return _jsCookie.default.set(_settings.LANGUAGE_KEY, language);
};

exports.setLanguage = setLanguage;

var saveToLocalStorage = function saveToLocalStorage(name, content) {
  return window.localStorage.setItem(name, JSON.stringify(content));
};

exports.saveToLocalStorage = saveToLocalStorage;

var readFromLocalStorage = function readFromLocalStorage(name) {
  return JSON.parse(window.localStorage.getItem(name));
};

exports.readFromLocalStorage = readFromLocalStorage;

var cleanLocalStorage = function cleanLocalStorage(name) {
  return window.localStorage.removeItem(name);
};

exports.cleanLocalStorage = cleanLocalStorage;