"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.freeze");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _enum = _interopRequireDefault(require("@/utils/enum"));

var setting = Object.freeze({
  loading: {
    title: 'Loading',
    name: 'LoadingDemo'
  },
  flyin: {
    title: 'Flyin',
    name: 'FlyinDemo'
  },
  backToTop: {
    title: 'BackToTop',
    name: 'BackToTopDemo'
  }
});

var _default = (0, _enum.default)(setting);

exports.default = _default;