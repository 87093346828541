"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var componentsRouter = {
  path: '/components',
  component: _layout.default,
  redirect: '/components/list',
  name: 'ComponentDemo',
  meta: {
    title: 'Components'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/components-demo/index'));
      });
    },
    name: 'demoList',
    meta: {
      title: 'DemoList'
    }
  }, {
    path: 'loading',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/components-demo/loading'));
      });
    },
    name: 'LoadingDemo',
    meta: {
      title: 'Loading'
    }
  }, {
    path: 'flyin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/components-demo/flyin'));
      });
    },
    name: 'FlyinDemo',
    meta: {
      title: 'Flyin'
    }
  }, {
    path: 'back-to-top',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/pages/components-demo/backToTop'));
      });
    },
    name: 'BackToTopDemo',
    meta: {
      title: 'BackToTop'
    }
  }]
};
var _default = componentsRouter;
exports.default = _default;