"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  language: function language(state) {
    return state.app.language;
  },
  new_sysmessage_count: function new_sysmessage_count(state) {
    return state.app.new_sysmessage_count;
  }
};
var _default = getters;
exports.default = _default;