"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PharmacyDoctorList = exports.PharmacyInfo = exports.Pharmacy = void 0;
var Pharmacy = '/api/pharmacy_list';
exports.Pharmacy = Pharmacy;
var PharmacyInfo = '/api/pharmacy_info';
exports.PharmacyInfo = PharmacyInfo;
var PharmacyDoctorList = '/api/pharmacy_doctor_list';
exports.PharmacyDoctorList = PharmacyDoctorList;