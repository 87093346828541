"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EditExtraDoctorInfo = exports.GetExtraDoctorInfo = exports.EditDoctorBirthday = exports.DeletePromoterVisit = exports.SetPromoterVisitDoctor = exports.GetPromoterVisitDoctorList = exports.SetPromoterVisit = exports.GetPromoterVisitTypeList = exports.GetPromoterVisitList = exports.SetPromoterTag = exports.GetPromoterTag = exports.SetPromoterDoctorTag = exports.GetPromoterDoctorTag = exports.DeletePromoterFollow = exports.GetPromoterFollowList = exports.SetPromoterFollow = exports.PromoterFollowType = exports.DoctorReportList = exports.DoctorInfo = exports.Doctor = void 0;
var Doctor = '/api/doctor_list_h5';
exports.Doctor = Doctor;
var DoctorInfo = '/api/doctor_info';
exports.DoctorInfo = DoctorInfo;
var DoctorReportList = '/api/doctor_report_list';
exports.DoctorReportList = DoctorReportList;
var PromoterFollowType = '/api/get_promoter_follow_type';
exports.PromoterFollowType = PromoterFollowType;
var SetPromoterFollow = '/api/set_promoter_follow';
exports.SetPromoterFollow = SetPromoterFollow;
var GetPromoterFollowList = '/api/get_promoter_follow_list';
exports.GetPromoterFollowList = GetPromoterFollowList;
var DeletePromoterFollow = '/api/delete_promoter_follow';
exports.DeletePromoterFollow = DeletePromoterFollow;
var GetPromoterDoctorTag = '/api/get_promoter_doctor_tag';
exports.GetPromoterDoctorTag = GetPromoterDoctorTag;
var SetPromoterDoctorTag = '/api/set_promoter_doctor_tag';
exports.SetPromoterDoctorTag = SetPromoterDoctorTag;
var GetPromoterTag = '/api/get_promoter_tag';
exports.GetPromoterTag = GetPromoterTag;
var SetPromoterTag = '/api/set_promoter_tag';
exports.SetPromoterTag = SetPromoterTag;
var GetPromoterVisitList = '/api/get_promoter_visit_list';
exports.GetPromoterVisitList = GetPromoterVisitList;
var GetPromoterVisitTypeList = '/api/get_promoter_visit_type';
exports.GetPromoterVisitTypeList = GetPromoterVisitTypeList;
var SetPromoterVisit = '/api/set_promoter_visit';
exports.SetPromoterVisit = SetPromoterVisit;
var GetPromoterVisitDoctorList = '/api/get_promoter_visit_doctor_list';
exports.GetPromoterVisitDoctorList = GetPromoterVisitDoctorList;
var SetPromoterVisitDoctor = '/api/set_promoter_visit_doctor';
exports.SetPromoterVisitDoctor = SetPromoterVisitDoctor;
var DeletePromoterVisit = '/api/delete_promoter_visit';
exports.DeletePromoterVisit = DeletePromoterVisit;
var EditDoctorBirthday = '/api/edit_doctor_birthday';
exports.EditDoctorBirthday = EditDoctorBirthday;
var GetExtraDoctorInfo = '/api/get_extra_doctor_info';
exports.GetExtraDoctorInfo = GetExtraDoctorInfo;
var EditExtraDoctorInfo = '/api/edit_doctor_info';
exports.EditExtraDoctorInfo = EditExtraDoctorInfo;