"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PromoterPartnersIndex = exports.PromoterPartners = exports.PartnerSalesReport = exports.PartnerTeam = void 0;
var PartnerTeam = '/api/partner_team';
exports.PartnerTeam = PartnerTeam;
var PartnerSalesReport = '/api/partner_sales_report';
exports.PartnerSalesReport = PartnerSalesReport;
var PromoterPartners = '/api/promoter_partners';
exports.PromoterPartners = PromoterPartners;
var PromoterPartnersIndex = '/api/promoter_partners_index';
exports.PromoterPartnersIndex = PromoterPartnersIndex;