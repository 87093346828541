"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _components = _interopRequireDefault(require("@/enum/components"));

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ComponentsDemo',
  data: function data() {
    return {
      components: _components.default.$getValues()
    };
  },
  methods: {
    handleToPage: function handleToPage(name) {
      this.$router.push({
        name: name
      });
    }
  }
};
exports.default = _default;