"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var bus = new _vue.default({
  methods: {
    on: function on(event, callback) {
      this.$on(event, callback);
      return this;
    },
    once: function once(event, callback) {
      this.$once(event, callback);
      return this;
    },
    off: function off(event, callback) {
      this.$off(event, callback);
      return this;
    },
    emit: function emit(event) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this.$emit.apply(this, [event].concat(args));
      return this;
    }
  }
});
var _default = {
  install: function install(vue) {
    Object.defineProperty(vue.prototype, '$bus', {
      get: function get() {
        return bus;
      }
    });
  }
};
exports.default = _default;