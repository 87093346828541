var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("van-nav-bar", {
    attrs: {
      title: _vm.pageTitle,
      "left-arrow": !(
        _vm.pageTitle == "首页" ||
        _vm.pageTitle == "医生" ||
        _vm.pageTitle == "我的团队" ||
        _vm.pageTitle == "我的"
      )
    },
    on: { "click-left": _vm.onClickLeft, "click-right": _vm.onClickRight },
    scopedSlots: _vm._u(
      [
        _vm.rightTitle
          ? {
              key: "right",
              fn: function() {
                return [
                  _vm.messagesCount != 0 && _vm.rightTitle == "消息"
                    ? _c(
                        "van-badge",
                        { attrs: { content: _vm.messagesCount } },
                        [_c("div", [_vm._v(_vm._s(_vm.rightTitle))])]
                      )
                    : _vm.$route.meta.is_alert
                    ? _c(
                        "div",
                        [_c("van-icon", { attrs: { name: "question-o" } })],
                        1
                      )
                    : _c("div", [_vm._v(_vm._s(_vm.rightTitle))])
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }