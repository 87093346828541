"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _AppMain = _interopRequireDefault(require("./components/AppMain"));

var _Navbar = _interopRequireDefault(require("./components/Navbar"));

//
//
//
//
//
//
//
var _default = {
  name: 'Layout',
  components: {
    Navbar: _Navbar.default,
    AppMain: _AppMain.default
  },
  computed: {
    hideNav: function hideNav() {
      return this.$route.meta.hideNav;
    }
  }
};
exports.default = _default;