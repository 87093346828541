"use strict";

var _interopRequireDefault = require("/Users/xayoung/Documents/Work/xlz-h5-sales/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "testHttpInteractor", {
  enumerable: true,
  get: function get() {
    return _commonInteractor.default;
  }
});
Object.defineProperty(exports, "testInteractor", {
  enumerable: true,
  get: function get() {
    return _testInteractor.default;
  }
});
Object.defineProperty(exports, "loginInteractor", {
  enumerable: true,
  get: function get() {
    return _loginInteractor.default;
  }
});
Object.defineProperty(exports, "indexInteractor", {
  enumerable: true,
  get: function get() {
    return _indexInteractor.default;
  }
});
Object.defineProperty(exports, "doctorInteractor", {
  enumerable: true,
  get: function get() {
    return _doctorInteractor.default;
  }
});
Object.defineProperty(exports, "teamInteractor", {
  enumerable: true,
  get: function get() {
    return _teamInteractor.default;
  }
});
Object.defineProperty(exports, "pharmacyInteractor", {
  enumerable: true,
  get: function get() {
    return _pharmacyInteractor.default;
  }
});
Object.defineProperty(exports, "partnerInteractor", {
  enumerable: true,
  get: function get() {
    return _partnerInteractor.default;
  }
});
Object.defineProperty(exports, "orderInteractor", {
  enumerable: true,
  get: function get() {
    return _orderInteractor.default;
  }
});
Object.defineProperty(exports, "mallInteractor", {
  enumerable: true,
  get: function get() {
    return _mallInteractor.default;
  }
});

var _commonInteractor = _interopRequireDefault(require("./interactors/common-interactor"));

var _testInteractor = _interopRequireDefault(require("./interactors/test-interactor"));

var _loginInteractor = _interopRequireDefault(require("./interactors/login-interactor"));

var _indexInteractor = _interopRequireDefault(require("./interactors/index-interactor"));

var _doctorInteractor = _interopRequireDefault(require("./interactors/doctor-interactor"));

var _teamInteractor = _interopRequireDefault(require("./interactors/team-interactor"));

var _pharmacyInteractor = _interopRequireDefault(require("./interactors/pharmacy-interactor"));

var _partnerInteractor = _interopRequireDefault(require("./interactors/partner-interactor"));

var _orderInteractor = _interopRequireDefault(require("./interactors/order-interactor"));

var _mallInteractor = _interopRequireDefault(require("./interactors/mall-interactor"));