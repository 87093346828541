import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import { getToken, removeToken, saveToLocalStorage } from '@/core/services/cache';
import { indexInteractor } from '@/core';
import context from '../../main.js';
var state = {
  token: getToken()
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USERINFO: function SET_USERINFO(state, userInfo) {
    state.userInfo = userInfo;
  }
};
var actions = {
  getInfo: function getInfo(_ref) {
    var commit = _ref.commit,
        state = _ref.state;
  },
  logout: function logout(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state,
        dispatch = _ref2.dispatch;
    commit('SET_TOKEN', '');
    removeToken();
  },
  resetToken: function resetToken(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  },
  // 刷新用户信息
  refreshUserInfo: function refreshUserInfo(_ref4, p) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      indexInteractor.getPromoterInfo().then(function (res) {
        // 存用户信息，token
        // console.log(res)
        context.$bus.emit('refreshUserInfo', res);
        commit('SET_USERINFO', saveToLocalStorage(res));
        resolve(res);
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};