"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LANGUAGE_KEY = exports.TOKEN_KEY = exports.TITLE = void 0;
var TITLE = '业务查询系统';
exports.TITLE = TITLE;
var TOKEN_KEY = 'XLZ';
exports.TOKEN_KEY = TOKEN_KEY;
var LANGUAGE_KEY = 'language';
exports.LANGUAGE_KEY = LANGUAGE_KEY;